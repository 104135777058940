"use client";

import { Flex, Grid, Skeleton, SkeletonText } from "@chakra-ui/react";

const generateSimpleArr = (qt) => {
  return Array.from(Array(qt).keys());
};

const HomeSkeleton = () => {
  return (
    <Flex flexDir={"column"} w={"100%"} overflow={"hidden"}>
      <Flex flexDir={"column"} alignItems={"center"} rowGap={"6px"}>
        <Skeleton
          w={{ base: "100%", md: "70%" }}
          h={"96px"}
          borderRadius={"4px"}
        />
        <Skeleton
          w={{ base: "100%", md: "60%" }}
          h={"96px"}
          borderRadius={"4px"}
        />
        <Skeleton
          mt={"24px"}
          w={{ base: "100%", md: "50%" }}
          h={"40px"}
          borderRadius={"8px"}
        />
        <Flex alignItems={"center"} columnGap={"12px"}>
          {generateSimpleArr(4).map((sk) => (
            <Skeleton key={sk} w={"80px"} h={"30px"} borderRadius={"4px"} />
          ))}
        </Flex>
        <Skeleton
          mt={"18px"}
          mb={"40px"}
          w={{ base: "100%", md: "180px" }}
          h={"40px"}
        />
        <Skeleton mt={"24px"} w={{ base: "100%", md: "40%" }} h={"14px"} />
      </Flex>
      <Flex
        flexDir={"column"}
        w={"100%"}
        alignItems={"center"}
        rowGap={"20px"}
        mt={"20px"}
      >
        {generateSimpleArr(2).map((bl) => (
          <Flex
            key={bl}
            ml={bl == 1 ? "40px" : "0"}
            alignItems={"center"}
            columnGap={"20px"}
          >
            {generateSimpleArr(7).map((skb) => (
              <Skeleton
                key={skb}
                {...{ isLoaded: true }}
                bg={"#7E869E11"}
                borderRadius={"12px"}
              >
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  p={"20px"}
                  w={"160px"}
                  h={"100px"}
                >
                  <Skeleton w={"80px"} h={"80px"} />
                </Flex>
              </Skeleton>
            ))}
          </Flex>
        ))}
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          alignItems={"center"}
          gap={"24px"}
          w={"100%"}
        >
          <Skeleton
            {...{ isLoaded: true }}
            bg={"#7E869E11"}
            borderRadius={"12px"}
            mt={"60px"}
            w={"100%"}
            maxW={"100%"}
            h={"440px"}
          >
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              rowGap={"30px"}
              h={"100%"}
            >
              <Skeleton h={"64px"} w={"60%"} />
              <SkeletonText w={"80%"} />
              <Skeleton h={"40px"} w={"260px"} />
            </Flex>
          </Skeleton>
          <Flex
            flexDir={"column"}
            w={"100%"}
            pt={"20px"}
            maxW={{ base: "100%", md: "630px" }}
          >
            <Skeleton w={"120px"} h={"30px"} />
            <Grid
              display={{ base: "none", md: "grid" }}
              templateColumns={{
                base: "repeat(3, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              }}
              mt={"20px"}
              gap={"14px"}
              w={"100%"}
            >
              {generateSimpleArr(6).map((sk) => (
                <Skeleton
                  key={sk}
                  {...{ isLoaded: true }}
                  bg={"#7E869E11"}
                  borderRadius={"12px"}
                >
                  <Flex
                    flexDir={"column"}
                    rowGap={"8px"}
                    h={"135px"}
                    p={"10px"}
                  >
                    <Skeleton w={"70px"} h={"14px"} />
                    <SkeletonText />
                  </Flex>
                </Skeleton>
              ))}
            </Grid>

            <Grid
              display={{ base: "grid", md: "none" }}
              templateColumns={{
                base: "minmax(0, 1fr)",
              }}
              mt={"20px"}
              gap={"14px"}
              w={"100%"}
            >
              {generateSimpleArr(2).map((sk) => (
                <Skeleton
                  key={sk}
                  {...{ isLoaded: true }}
                  bg={"#7E869E11"}
                  borderRadius={"12px"}
                >
                  <Flex
                    flexDir={"column"}
                    rowGap={"8px"}
                    h={"135px"}
                    p={"10px"}
                  >
                    <Skeleton w={"70px"} h={"14px"} />
                    <SkeletonText />
                  </Flex>
                </Skeleton>
              ))}
            </Grid>
            <Skeleton w={"50px"} h={"12px"} mt={"12px"} alignSelf={"center"} />
          </Flex>
        </Flex>

        <Flex alignItems={"center"} columnGap={"20px"}>
          {generateSimpleArr(5).map((skb) => (
            <Skeleton
              key={skb}
              {...{ isLoaded: true }}
              bg={"#7E869E11"}
              borderRadius={"12px"}
            >
              <Flex
                alignItems={"start"}
                columnGap={"12px"}
                p={"20px"}
                w={"360px"}
                h={"140px"}
              >
                <Skeleton w={"80px"} h={"80px"} />
                <SkeletonText />
              </Flex>
            </Skeleton>
          ))}
        </Flex>

        <Grid
          templateColumns={{
            base: "minmax(0, 1fr)",
            lg: "repeat(2, minmax(0, 1fr))",
          }}
          gap={"24px"}
        >
          <Skeleton
            {...{ isLoaded: true }}
            bg={"#7E869E11"}
            borderRadius={"12px"}
            pt={"20px"}
          >
            <Skeleton w={"160px"} h={"30px"} mb={"20px"} />
            <Flex flexDir={"column"} rowGap={"10px"} p={"20px"}>
              {generateSimpleArr(7).map((s) => (
                <SkeletonText key={s} h={"80px"} />
              ))}
            </Flex>
          </Skeleton>
          <Flex flexDir={"column"} rowGap={"24px"}>
            <Skeleton
              {...{ isLoaded: true }}
              bg={"#7E869E11"}
              borderRadius={"12px"}
              pt={"20px"}
              minH={{ base: undefined, lg: "440px" }}
            >
              <Skeleton w={"160px"} h={"30px"} mb={"20px"} />
              <Flex
                alignItems={"start"}
                justifyContent={"center"}
                columnGap={"10px"}
                rowGap={"10px"}
                flexWrap={"wrap"}
                p={"20px"}
              >
                {generateSimpleArr(27).map((s) => (
                  <Skeleton
                    key={"home3" + s}
                    h={"30px"}
                    w={"100%"}
                    maxW={"70px"}
                  />
                ))}
              </Flex>
            </Skeleton>
            <Skeleton
              {...{ isLoaded: true }}
              bg={"#7E869E11"}
              borderRadius={"12px"}
              pt={"20px"}
              h={{ base: "100%" }}
            >
              <Skeleton w={"160px"} h={"30px"} mb={"20px"} />
              <Flex
                alignItems={"start"}
                justifyContent={"space-between"}
                columnGap={"10px"}
                rowGap={"10px"}
                p={"20px"}
              >
                {generateSimpleArr(3).map((s) => (
                  <Skeleton
                    key={"home2" + s}
                    {...{ isLoaded: true }}
                    bg={"#7E869E11"}
                    borderRadius={"12px"}
                    p={"12px"}
                    w={{ base: "100%" }}
                    maxW={"160px"}
                  >
                    <Flex
                      flexDir={"column"}
                      alignItems={"center"}
                      minW={"100%"}
                      rowGap={"10px"}
                    >
                      <Skeleton h={"68px"} w={"68px"} />
                      <Skeleton h={"12px"} w={"100px"} />
                      <Skeleton h={"12px"} w={"100%"} />
                      <Skeleton h={"12px"} w={"100%"} />
                      <Skeleton h={"40px"} minW={"100%"} />
                    </Flex>
                  </Skeleton>
                ))}
              </Flex>
            </Skeleton>
          </Flex>
        </Grid>
        <Grid
          templateColumns={{
            base: "minmax(0, 1fr)",
            lg: "repeat(2, minmax(0, 1fr))",
          }}
          gap={"24px"}
        >
          <Flex
            flexDir={"column"}
            w={"100%"}
            pt={"20px"}
            maxW={{ base: "100%", md: "630px" }}
          >
            <Skeleton w={"120px"} h={"30px"} />
            <Grid
              display={{ base: "none", md: "grid" }}
              templateColumns={{
                base: "repeat(3, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
              }}
              mt={"20px"}
              gap={"14px"}
              w={"100%"}
            >
              {generateSimpleArr(6).map((sk) => (
                <Skeleton
                  key={"home1" + sk}
                  {...{ isLoaded: true }}
                  bg={"#7E869E11"}
                  borderRadius={"12px"}
                >
                  <Flex
                    flexDir={"column"}
                    rowGap={"8px"}
                    h={"135px"}
                    p={"10px"}
                  >
                    <Skeleton w={"70px"} h={"14px"} />
                    <SkeletonText />
                  </Flex>
                </Skeleton>
              ))}
            </Grid>
            <Grid
              display={{ base: "grid", md: "none" }}
              templateColumns={{
                base: "minmax(0, 1fr)",
              }}
              mt={"20px"}
              gap={"14px"}
              w={"100%"}
            >
              {generateSimpleArr(2).map((sk) => (
                <Skeleton
                  key={"home" + sk}
                  {...{ isLoaded: true }}
                  bg={"#7E869E11"}
                  borderRadius={"12px"}
                >
                  <Flex
                    flexDir={"column"}
                    rowGap={"8px"}
                    h={"135px"}
                    p={"10px"}
                  >
                    <Skeleton w={"70px"} h={"14px"} />
                    <SkeletonText />
                  </Flex>
                </Skeleton>
              ))}
            </Grid>
            <Skeleton w={"50px"} h={"12px"} mt={"12px"} alignSelf={"center"} />
          </Flex>
          <Skeleton
            {...{ isLoaded: true }}
            bg={"#7E869E11"}
            borderRadius={"12px"}
            mt={"60px"}
            w={"100%"}
            maxW={"100%"}
            h={"440px"}
          >
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              rowGap={"30px"}
              h={"100%"}
            >
              <Skeleton h={"64px"} w={"60%"} />
              <SkeletonText w={"80%"} />
              <Skeleton h={"40px"} w={"260px"} />
              <Skeleton h={"40px"} w={"260px"} />
            </Flex>
          </Skeleton>
        </Grid>

        <Flex alignItems={"center"} columnGap={"20px"}>
          {generateSimpleArr(5).map((skb) => (
            <Skeleton
              key={"person" + skb}
              {...{ isLoaded: true }}
              bg={"#7E869E11"}
              borderRadius={"12px"}
            >
              <Flex
                alignItems={"start"}
                columnGap={"12px"}
                p={"20px"}
                w={"360px"}
                h={"140px"}
              >
                <Skeleton w={"80px"} h={"80px"} />
                <SkeletonText />
              </Flex>
            </Skeleton>
          ))}
        </Flex>

        <Skeleton
          {...{ isLoaded: true }}
          bg={"#7E869E11"}
          borderRadius={"12px"}
        >
          <Grid
            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
            py={"24px"}
            px={"40px"}
            gap={"40px"}
          >
            <Flex
              flexDir={"column"}
              gridRow={{ base: "2", md: "1" }}
              rowGap={"16px"}
            >
              <Skeleton w={"60%"} h={"12px"} />
              <Skeleton w={"100%"} h={"250px"} />
              <SkeletonText w={"80%"} />
            </Flex>
            <Flex
              gridRow={{ base: "1" }}
              flexDir={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              rowGap={"36px"}
            >
              <Skeleton h={{ base: "24px", md: "40px" }} w={"100%"} />
              <SkeletonText w={"80%"} />
            </Flex>
          </Grid>
        </Skeleton>

        <Grid
          templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
          py={"24px"}
          px={"40px"}
          gap={"40px"}
        >
          <Flex
            flexDir={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            rowGap={"36px"}
          >
            <Skeleton h={{ base: "24px", md: "40px" }} w={"100%"} />
            <SkeletonText w={"80%"} />
          </Flex>
          <Flex flexDir={"column"} rowGap={"16px"}>
            <Skeleton w={"60%"} h={"12px"} />
            <Skeleton w={"100%"} h={"250px"} />
            <SkeletonText w={"80%"} />
          </Flex>
        </Grid>
        <Skeleton
          {...{ isLoaded: true }}
          bg={"#7E869E11"}
          borderRadius={"12px"}
          w={{ base: "100%", md: "80%" }}
          alignSelf={"center"}
        >
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }}>
            <Flex />
            <Flex
              flexDir={"column"}
              h={"274px"}
              p={"20px"}
              rowGap={"40px"}
              alignItems={{ base: "center", md: "start" }}
              justifyContent={"center"}
            >
              <Skeleton w={"60%"} h={{ base: "16px", md: "70px" }} />
              <Skeleton w={"140px"} h={"40px"} />
            </Flex>
          </Grid>
        </Skeleton>
      </Flex>
    </Flex>
  );
};

export default HomeSkeleton;
